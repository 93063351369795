.social-wall {
	margin-bottom: 26px;

	h3 {
		@include h2;
		margin-bottom: 23px;
		font-size: 23px;
		line-height: 27px;

		a {
			color: inherit;
		}
	}

	&__heading {
		margin-left: auto;
  	margin-right: auto;
		text-align: center;
  	max-width: 1050px;
	}

	p {
		@include subheading();
		margin-bottom: 42px;

		a {
			color: inherit;
		}
	}

	.crt-feed {
		max-width: 1070px;
		width: 100%;
		margin: auto;
	}

	@include breakpoint(small only) {
		margin: 0 21px;
	}

	.crt-grid-post {
		padding: 0 10px !important;
	  }


	  @include breakpoint(small only) {
		.social-wall__feed .crt-grid-post {
			width: 50% !important;
		  }

		  .social-wall__feed .crt-grid-post:first-child {
			padding-right: 8px !important;
		  }
	  }
}
