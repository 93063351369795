@mixin slider-caption {
	font-family: $primary-font;
	font-size: 32px;
	line-height: 38px;
	text-transform: uppercase;
	letter-spacing: 1px;

	@include breakpoint(medium) {
		font-size: 46px;
		line-height: 50px;
	}
}


@mixin h1 {
	font-family: $primary-font;
	font-size: 40px;
	line-height: 45px;
	letter-spacing: 0.5px;
	color: get-color('mid-grey');
	text-transform: uppercase;

	@include breakpoint(small only) {
		font-size: 34px;
		line-height: 39px;
	}
}

@mixin h2 {
	font-family: $primary-font;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 0.5px;
	color: get-color('mid-grey');
	text-transform: uppercase;
}


@mixin subheading {
	font-family: $secondary-font;
	font-size: 12px;
	line-height: 19px;
	letter-spacing: 1px;
	color: get-color('black');
	text-transform: uppercase;
	font-style: normal;
	font-weight: 600;
}


@mixin price {
	font-family: $primary-font;
	font-size: 20px;
	line-height: 23px;
	color: get-color('red');
}


@mixin burger-menu-sub-nav {
	font-family: $primary-font;
	font-size: 25px;
	line-height: 27px;
	color: get-color('white');
}


@mixin body-copy {
	font-family: $secondary-font;
	font-size: 15px;
	line-height: 25px;
	color: get-color('black');
	font-weight: 400;
	font-style: normal;
}


@mixin bullet-list {
	font-family: $secondary-font;
	font-weight: bold;
	font-size: 14px;
	line-height: 27px;
	color: get-color('black');
}


@mixin menu-list {
	font-family: $secondary-font;
	font-size: 15px;
	line-height: 25px;
	color: get-color('black');
}


@mixin terms-conditions {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 17px;
	color: get-color('black');
}


@mixin smallest {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 14px;
	text-transform: uppercase;
	color: get-color('black');
}
