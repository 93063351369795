.button {
	border: 1px solid get-color('mid-grey');
	color: get-color('white');
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 1px;
	padding: 10px 12px;
	text-transform: uppercase;
	background-color: get-color('mid-grey');
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	font-family: $primary-font;


	&:hover,
	&:active,
	&:focus {
		background-color: get-color('red');
		border-color: get-color('red');
		color: #fff;
	}

	&--primary {
		color: get-color('white');
		background-color: get-color('red');
		border-color: get-color('red');

		&:hover,
		&:active,
		&:focus {
			background-color: get-color('black');
			border-color: get-color('black');
			color: get-color('white');
		}
	}
}

.multiple-buttons {
	text-align: left;
	width: calc(100% + 24px);
	margin-left: -12px;
	margin-right: -12px;

	.button {
		margin: 0 12px 12px;
	}
}
