.contact {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;

	@include breakpoint(medium only) {
		padding: 0 20px;
	}
	
	&__details {
		@include breakpoint(small only) {
			padding: 0 20px 41px;
		}
		
		@include breakpoint(medium) {
			padding-right: 20px;
		}
		
		@include breakpoint(large) {
			padding-right: 40px;
		}
		
		h3 {
			@include subheading();
			margin-bottom: 34px;
		}
		
		p {
			@include body-copy();
		}
		
		
	}
	
	&__form {
		background-color: get-color('light-grey');
		padding: 52px 20px 17px;
		
		@include breakpoint(large) {
			padding: 60px 40px 27px;
		}
		
		h2 {
			@include h2;
			margin-bottom: 27px;
		}
		
		.button {
			min-width: 117px;
		}
	}
}