.detail {
	text-align: center;
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 116px;

	&__inner {
		background-color: get-color('pale-grey');
		padding: 55px 20px 45px;
	}

	&__features {
		width: 100%;
		max-width: 450px;
		margin: 0 auto 20px;
		padding: 0 15px;

		h2 {
			@include h2;
			margin-bottom: 20px;
			font-size: 24px;
			line-height: 24px;
		}

		&-list {
			text-align: left;

			ul {
				margin: 0;
			}

			li {
				@include bullet-list();
				margin-bottom: 0;
			}
		}
	}

	&__cta {
		padding-top: 7px;
	}

	&__rate {
		@include price;
		margin-bottom: 35px;
	}
}
