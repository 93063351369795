.accommodation {
	width: 100%;
	max-width: 1104px;
	margin: 0 auto;

	&__item {
		padding: 0 20px;
		margin-bottom: 60px;

		@include breakpoint(medium) {
			&:last-child:nth-child(odd) {
				margin-left: auto;
				margin-right: auto;
			}
		}
		
		@include breakpoint(large) {
			padding: 0 30px;
			margin-bottom: 107px;
		}

		&-inner {
			background-color: get-color('white');
		}
	}

	&__image {
		margin-bottom: 33px;
	}

	&__content {
		text-align: center;
		padding: 0 10px;

		@include breakpoint(large) {
			padding: 0 60px;
		}

		h2 {
			@include h2;
			margin-bottom: 29px;
			font-size: 24px;
			line-height: 24px;
		}

		h3 {
			@include subheading();
			margin-bottom: 32px;
		}

		h2,
		h3 {
			a {
				color: inherit;
			}
		}

		p {
			@include body-copy();
			margin-bottom: 22px;
		}
	}

	&__details {
		text-align: center;

		h4 {
			@include price();
		}
	}

	&__price {
		padding-top: 20px;
		padding-bottom: 7px;
		color: get-color('red');
	}

	.multiple-buttons {
		padding: 20px 0 60px;
		text-align: center;
	}
}
