.offers-detail {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 80px;
	padding-top: 50px;
	
	&__intro {
		text-align: left;
		margin-right: 0;
		margin-bottom: 0;
		padding: 0;
		max-width: none;
		
		@include breakpoint(small only) {
			margin-bottom: 80px;
		}
		
		@include breakpoint(medium down) {
			padding: 0 20px;
		}
		
		@include breakpoint(medium) {
			padding-right: 50px;
		}
		
		@include breakpoint(large) {
			margin-left: 8.33333%;
		}
		
		.multiple-buttons {
			text-align: left;
		}
	}
	
	&__price {
		@include price();
		padding-top: 10px;
	}
	
	&__details {
		&-inner {
			padding: 40px 20px;
			background-color: get-color('light-grey');
			
			@include breakpoint(large) {
				padding: 30px 40px;
			}
		}
		
		h2 {
			@include h2;
			margin-bottom: 20px;
		}
		
		p {
			@include body-copy();
		}
		
		ul {
			@include bullet-list();
		}
	}
}