.terms {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;
	
	@include breakpoint(medium down) {
		padding: 0 20px;
	}
	
	&__inner {
		@include breakpoint(medium) {
			padding-right: 50px;
		}
	}
	
	h2 {
		@include subheading();
		margin-bottom: 10px;
	}
	
	p,
	li {
		@include terms-conditions();
	}
}