@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
	primary: #535A52, // very dark grey/geeen
	secondary: #767676, // default
	success: #3adb76, // default
	warning: #ffae00, // default
	alert: #cc4b37, // default
	'black': #2c2a29,
	'white': #fff,
	'grey': #707070,
	'light-grey': #f0f0f0,
	'gold': #ad7e00,
	'claret': #810000,
	'red': #D22630,
	'pale-grey': #e3e2e2,
	'mid-grey': #8c8279
);

$body-background: #fff;

$primary-font: 'Micro Extended', sans-serif;
// regular 400 semibold 600 bold 700
$secondary-font: 'adobe-caslon-pro', sans-serif;
