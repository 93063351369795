.intro {
	width: 100%;
	max-width: 1050px;
	margin: 0 auto 40px;
	padding: 40px 20px 39px;
	text-align: center;

	h1 {
		@include h1;
		margin-bottom: 32px;
	}

	h2 {
		@include subheading();
		margin-bottom: 32px;
	}

	p {
		@include body-copy;
		margin-bottom: 19px;
		max-width: 496px;
		width: 100%;
		margin: auto;
	}

	.multiple-buttons {
		padding-top: 37px;
		text-align: center;
	}
}
