.other-rooms {
	text-align: center;
	width: 100%;
	max-width: 1100px;
	margin: 0 auto 120px;
	
	@media screen and (max-width: 1150px) {
		padding: 0 20px;
	}
	
	h2 {
		@include h2;
		margin-bottom: 45px;
	}
	
	&__room {
		padding: 0 25px 0;
		transition: all 0.3s ease-in-out;
	}
	
	&__image {
		margin-bottom: 0;
	}
	
	&__content {
		padding: 33px 4% 40px;
		background-color: get-color('light-grey');
		
		h3 {
			@include h2;
			font-size: 18px;
			margin-bottom: 0;
			
			a {
				color: inherit;
			}
		}
		
		.multiple-buttons {
			margin: 0;
			text-align: center;
		}
	}
	
	&__rate {
		@include price;
		margin-bottom: 37px;
		padding-top: 24px;
	}
	
	.slick-arrow {
		position: absolute;
		z-index: 100;
		top: 240px;
		width: 21px;
		height: 21px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		text-indent: -12345px;
		background: no-repeat;
		background-size: 27px 21px;
		
		&:hover,
		&:active {
			width: 27px;
		}
		
		&.slick-prev {
			left: -15px;
			background-image: url('img/carousel-prev.svg');
			background-position: left center;
		}
		
		&.slick-next {
			right: -15px;
			background-image: url('img/carousel-next.svg');
			background-position: right center;
		}
	}
	
	
	&__carousel.one-room {
		max-width: 400px;
		margin: auto;
	}
	
	&__carousel.two-rooms {
		max-width: 750px;
		margin: auto;
	}
	
	&__carousel.center-mode {
		.other-rooms {
			&__room {
				@include breakpoint(large) {
					padding-top: 75px;
				}
			}
			
			&__content .multiple-buttons {
				@include breakpoint(large) {
					display: none;
				}
			}
			
			&__rate {
				@include breakpoint(large) {
					display: none;
				}
			}
		}
		
		.slick-current .other-rooms {
			&__room {
				@include breakpoint(large) {
					padding-top: 0;
				}
			}
			
			&__content .multiple-buttons {
				@include breakpoint(large) {
					display: block;
				}
			}
			
			&__rate {
				@include breakpoint(large) {
					display: block;
				}
			}
		}
	}
}