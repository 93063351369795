.landing {
	margin-bottom: 90px;
	
	&__slider,
	&__content {
		transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01), opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
		will-change: transform, opacity;
		backface-visibility: hidden;
		transition-duration: 1250ms;
		opacity: 0;
	}
	
	&__item {
		margin-bottom: 120px;
		
		@include breakpoint(small only) {
			margin-bottom: 60px;
		}
		
		&:nth-child(even) {
			.landing__slider {
				transform: translateX(100px);
				
				@include breakpoint(medium) {
					order: 2;
					margin-left: 0;
				}
				
				&.landing-alternating-fade-in {
					transform: translateX(0);
					opacity: 1;
				}
			}
			
			.landing__content {
				transform: translateX(-100px);
				
				@include breakpoint(medium) {
					order: 1;
				}
				
				@include breakpoint(large) {
					margin-left: 8.33333%;
				}
				
				&.landing-alternating-fade-in {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
		
		&:nth-child(odd) {
			.landing__slider {
				transform: translateX(-100px);
				
				&.landing-alternating-fade-in {
					transform: translateX(0);
					opacity: 1;
				}
			}
			
			.landing__content {
				transform: translateX(100px);
				
				&.landing-alternating-fade-in {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}
	
	&__slider {
		position: relative;
		min-height: 300px;
		
		@include breakpoint(large) {
			min-height: 425px;
		}
		
		&-inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;	
		}
		
		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide > div {
			height: 100%;
		}
		
		&-image {
			background: no-repeat center center;
			background-size: cover;
			
			a,
			picture {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
			
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		
		.reform-slider-arrows {
			display: none;
		}
	}
	
	&__content {
		padding: 60px 5%;
		@include flex-align-center();
		text-align: left;
		background-color: get-color('light-grey');
		
		h2 {
			@include h2;
			margin-bottom: 2px;
			
			a {
				color: inherit;
			}
		}
		
		h3 {
			@include subheading();
			margin-bottom: 12px;
			
			a {
				color: inherit;
			}
		}
		
		p {
			@include body-copy();
		}
		
		.multiple-buttons {
			padding-top: 40px;
		}
	}
	
}